.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow: scroll;
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.body::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.body::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.mainTitle {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    letter-spacing: -1.1px;
}

.mainImg {
    margin-right: 10.3px;
    width: 22px;
}


.selectMenu {
    display: flex;
    margin-top: 24px;
}

.selectedMenu{
    width: 177px;
    height: 51px;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    color : #888888;
    font-family: "Apple_R";
    cursor: pointer;
}

.selectedMenu.active{
    color: white;
    background-color: #3d50b0;
    border: transparent;
}

.moneyCharge {
    display: flex;
    width: 1200px;
    background-color: white;
    height: 67px;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
    border-radius: 10px;
}

.moneyCharge div:first-child {
    font-family: "Apple_R";
    font-size: 19px;
    color: #121a50;
}

.moneyCharge span:first-child{
    color: #566ee8;
}

.moneyCharge div:nth-child(2) {
    cursor: pointer;
    padding-left: 8px;
    font-family: "Apple_R";
    width: 123px;
    height: 39px;
    border: 1px solid #566ee8;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #566ee8;
}

.moneyCharge div:nth-child(2) img {
    margin-bottom: 4px;
}


.chargeTitle {
    border-radius: 4px 4px 0 0;
    font-family: "Apple_L";
    font-size: 18px;
    margin-bottom: 18px;
    background-color: #3d50b0;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.chargeBoard {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
}

.chargeAmount {
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeAmount2 {
    margin-top: 8px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeName {
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeBtnDiv {
    margin-top: 12px;
    border-top: 2px solid rgb(223, 223, 223);
    display: flex;
    justify-content: end;
    padding-top: 16px;
    padding-bottom: 16px;
}

.disabledChargedBtn {
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    background-color: rgb(224, 224, 224);
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(163, 163, 163);
    font-family: "Apple_L";
}

.chargeBtn {
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}


.accountInfoDiv {
    border-radius: 4px;
    background-color: white;
    width: 400px;
    height: 280px;
    padding-left: 12px;
    padding-right: 12px;
}

.accountInfoTitle {
    font-family: "Apple_R";
    font-size: 18px;
    margin-bottom: 8px;
}

.accountInfoDescription {
    border: 1.5px solid rgb(210, 210, 210);
    font-family: "Apple_R";
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}

.accountInfoBank {

    display: flex;
    margin-bottom: 6px;
}

.accountInfoBank div:first-child{
    color: rgb(120 , 120, 120);
    width: 120px;
}

.accountInfoNumber {
    display: flex;
    margin-bottom: 6px;

}

.accountInfoNumber div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoName{
    display: flex;
    margin-bottom: 6px;
}

.accountInfoName div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAmount {
    display: flex;
    margin-bottom: 6px;
}

.accountInfoAmount div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAddDesc {
    display: flex;
}

.accountInfoAddDesc div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAdd {
    margin-top: 16px;
    font-family: "Apple_R";
    font-size: 14px;
}

.accountInfoAdd2 {
    margin-top: 2px;
    font-family: "Apple_R";
    font-size: 14px;
}

.bankName{
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.bankDescription {
    font-size: 14px;
    font-family: "Apple_R";
}

.contentsLoading{
    width: 1200px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
