
.statusBody{
    padding-top: 2.469rem;
}

.statusTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90.4%;
    margin: 0 auto;
}

.statusTitle{
    font-family: "Apple_SB";
    font-size: 1.1rem;
    letter-spacing: -0.85px;
    color: #251e1c;
}

.statusNumber{
    font-size: 0.8rem;
    font-family: "Apple_L";
}

.description{
    margin-top: 0.812rem;
    background-color: #f6f6f6;
    padding-top: 1.632rem;
    padding-bottom: 1.632rem;
}

.descriptionBody{
    width: 90.6%;
    margin: 0 auto;
}

.eachDescription{
    margin-bottom: 1.484rem;
}

.eachDescription.last{
    margin-bottom: 0;
}

.eachDescriptionDate{
    font-family: "Apple_M";
    color: #9e9e9e;
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    margin-bottom: 0.634rem;
}

.eachDescriptionBox{
    width: 100%;
    padding: 1.397rem 1.094rem 1.141rem 1.094rem;
    border-radius: 10px;
    border: solid 0.5px #dbdbdb;
    background-color: #fff;
    position: relative;
}

.eachDescriptionText{
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.35px;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_M";
    margin-bottom: 0.25rem;

}

.eachDescriptionOption{
    font-family: "Apple_L";
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    text-align: left;
    color: #6B7583;
    margin-bottom: 0.2rem;
}

.eachDescriptionTargetDate{
    font-family: "Apple_L";
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    text-align: left;
    color: #251e1c;
    margin-bottom: 0.853rem;
}

.eachDescriptionKindDiv{
    display: flex;
    align-items: center;
}

.eachDescriptionKindText{
    font-family: "Apple_M";
    font-size: 0.95rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.85px;
    text-align: left;
    color: #251e1c;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eachDescriptionKindSuccess{
    margin-left: 0.719rem;
    display: flex;
    align-items: center;
    background-color: #3d55dc;
    border-radius: 12.5px;
    opacity: 0.9;
    border: 0,5px solid #b7b7b7;
    color: white;
    padding: 0.344rem 0.656rem 0.313rem 0.531rem;
}

.eachDescriptionKindSuccess > span{
    font-family: "Apple_SB";
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    text-align: left;
    color: #fff;
}

.check{
    width: 10px;
}

.xmark{
    width: 8px;
}

.arrowUp{
    width: 8px;
}

.trash{
    width: 14px;
}

.eachDescriptionKindSuccess.waiting{
    background-color: #92949c;
}

.eachDescriptionKindSuccess.decline{
    background-color: #eb445a;
}

.eachDescriptionKindSuccess.success{
    background-color: #3d55dc;
}

.trashCanDiv{
    position: absolute;
    right: 1.094rem;
    top: 1.397rem;
    padding: 0.3rem;
    padding-top: 0rem;
}

.eachDescriptionBox.cash{
    margin-bottom: 1.484rem;
    display: grid;
    grid-template-columns: 1.4fr 4.5fr 1.5fr;
}

.cashDescriptionDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cashDescription{
    font-size: 0.75rem;
    color: #607d8b;
    font-family: "Apple_SB";
}

.cashTitle{
    font-family: "Apple_B";
    font-size: 1.031rem;
    letter-spacing: -0.41px;
    text-align: left;
    color: #2a2a2a;
}

.btnDiv{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cashBtn{
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    font-size: 0.8rem;
    color: #455a64;
    border-radius: 0.4rem;
    font-family: "Apple_M";
}

.iconDiv{
    display: flex;
    align-items: center;

}

.circleDollar{
    width: 2.5rem;
}

.modalBody{
    width: 90.6%;
    margin: 0 auto;
    padding-top: 0.6rem;
    overflow-y: auto;
}

/* scroll 안 보이게 */
.modalBody::-webkit-scrollbar {
    display: none;
}

.modalTitle{
    font-family: "Apple_B";
    font-size: 1.1rem;
    color: #263238;
}

.inputDiv{
    margin-top: 0.8rem;
    color: #d32f2f;
}


.chargeBody{
    width: 90.6%;
    margin: 0 auto;
}

.chargeTitle{
    color: #263238;
    margin-top: 2.5rem;
    font-family: "Apple_B";
    font-size: 1.1rem;
    color: #263238;
}

.chargeSubTitle{
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-family: "Apple_M";
    color: #76767a;
    line-height: 1.6;
}

.infoBox{
    margin-top: 2.5rem;
    background-color: #f6f6f6;
    width: 80%;
    padding: 1.5rem 1.5rem;
    border-radius: 15px 15px 15px 0;
}

.infoBoxTitle{
    width: 100%;
    font-size: 1.1rem;
    color: #263238;
    display: flex;
    justify-content: space-between;
    font-family: "Apple_M";
}

.paste{
    width: 1.2rem;
}


.sendInfoBox{
    margin-top: 0.5rem;
}

.sendInfoBox.second{
    margin-top: 0.8rem;
}

.sendInfo{
    font-family: "Apple_R";
    font-size: 1rem;
}

.sendInfoBox .sendInfo:first-child{
    color: #3d50b0;
    margin-bottom: 0.2rem;
}

@media only screen and (min-width : 500px){
    .eachDescriptionBox.cash{
        grid-template-columns: 0.7fr 4.5fr 1.5fr;
    }
}

.ticketClickDivContainer{
    margin-top: 3.5rem;
    padding-bottom: 1.3rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 1.5rem;
}

.ticketClickDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticketClickDivTitle{
    font-family: "Apple_B";
}

.ticketClickDivContent{
    display: flex;
    justify-content: space-between;
    width: 8.125rem;
    align-items: center;
}

.ticketClickDivNumber{
    font-size: 1.5rem;
    color: #1f1f1f;
    font-weight: 500;
    font-family: "Apple_B";
}

.input{
    border: none;
    /* 눌렸을때도 테두리 없게 */
    outline: none;
    width: 2rem;
    font-size: 1.5rem;
    text-align: center;
}

/* 크롬, 사파리, 엣지, 오페라 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 파이어폭스 */
input[type=number] {
  -moz-appearance: textfield;
}

.ticketClickDivCircle{
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    background-color: transparent;
}

.ticketClickDivCircle:active{
    transform: scale(0.9);

    /* 0.1s */
    transition: transform 0.1s;
}

.ticketTextDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
}

.ticketTextDivText{
    color: #353535;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02rem;
    font-family: "Apple_M";
}

.ticketTextDivNumber{
    font-weight: 600;
    font-family: "Apple_SB";
}

.ticketTextDivNumber.ticketTextDivNumberBold{
    font-size: 2rem;
    color: rgb(11, 107, 203);
    letter-spacing: -0.04rem;
}

.phoneTitle{
    font-family: "Apple_B";
    font-size: 1.1rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.justHeight{
    height: 15rem;
}



