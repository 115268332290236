/* 화면 전체를 덮는 오버레이 */
.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 어두운 반투명 배경 */
    display: flex;
    align-items: flex-end; /* 아래에서부터 올라오게 하려면 flex-end 사용 */
    justify-content: center;
    animation: fadeIn 0.3s ease forwards;
    z-index: 9999; /* 다른 요소보다 위에 표시되도록 */
  }
  
  /* 모달 컨테이너 */
  .modal_content {
    width: 100%;      /* 화면 꽉 채우기(원한다면 다른 너비로 조정) */
    height: 100%;
    /*max-width: 768px; /* 혹시 최대 너비를 제한하고 싶다면 사용 */
    background-color: white;
    animation: slideUp 0.3s ease forwards;
  }
  
  /* 오버레이 페이드 인 애니메이션 */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* 모달이 아래서 위로 올라오는 애니메이션 */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }