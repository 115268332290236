/* MenuBar.module.css 예시 */

.menuBar {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 100%;
    /* 기타 스타일들... */

    font-family: "Apple_SB";
    width: 100%;
    padding-top: 1.4rem;
    padding-bottom: 0.9rem;
    border-bottom: 0.08rem solid #cfd8dc;
    color: #607d8b;
    position: relative;
  }
  
  .menuBarSection {
    flex: 1;
    text-align: center;
    cursor: pointer;
    /* 기타 스타일들... */
    user-select: none;

  /* iOS/안드로이드 등 모바일 웹에서 탭 했을 때 하이라이트 제거 */
  -webkit-tap-highlight-color: transparent;

  /* 클릭/포커스 outline 제거 */
  outline: none;
  }
  
  .menuBarSection.active {
    color: #37474f;
    /* 선택된 메뉴 스타일 */
  }
  
  /* UnderBar */
  .menuUnderBar {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #263238; /* 선 색상 */
    transition: all 200ms ease-out;
  }