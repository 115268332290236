.eachContent{
    width: 100%;
    position: relative;
    height: 100%;
}

.contentDescription{
    width: 100%;
    overflow: hidden;
}

.hoverIcon{
    position: absolute;
    right: 0;
    z-index: 99;
    display: none;
    cursor: pointer;
}

.eachContent:hover .hoverIcon{
    display: flex;
    cursor: pointer;
    width: 50px;
    justify-content: space-between;
}

.hoverIconOpened{
    display: block;
    cursor: pointer;
}

.eachContent:hover .contentDescription{
    width: 93%;
}

.fullScreenContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%; 
    height: 100%;
    background-color: white;
    overflow: auto;
    padding: 16px;   
  }

